/* Reset default browser styles if needed */
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root, app-root {
  height: 100%;
  display: flex;
}
